<template>
    <b-form-select v-model="model" :options="options" text-field="name" value-field="id"/>
</template>

<script>

import {PRINTERS_ENDPOINT} from '@constants';

export default {
    name: 'PrinterSelect',
    props: {
        value: {
            type: Number,
        },
        labelId: {
            type: Number,
        },
    },
    data() {
        return {
            printers: [],
        };
    },
    computed: {
        model: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit('input', value);
            },
        },
        options() {
            if (!this.labelId) {
                return [];
            }

            return this.printers;

            //return this.printers.filter((printer) => printer.hasOwnProperty('labels') && printer.labels.some((label) => label.id === this.labelId));
        }
    },
    methods: {
        load() {
            let params = {
                with: [
                    'labels',
                ],
            };

            this.$http.get(PRINTERS_ENDPOINT, {params}).then((response) => this.printers = response.data.data);
        },
    },
    mounted() {
        this.load();
    }
}
</script>
