<template>
    <b-form-select v-model="model">
        <b-form-select-option v-for="option of options" :key="option.name" :value="option">{{ option.name }}</b-form-select-option>
    </b-form-select>
</template>

<script>
import {LABELS_ENDPOINT, GROUP_PACK_LABEL} from '@constants';

export default {
    name: 'LabelSelect',
    props: {
        value: {
            type: Object,
        },
    },
    data() {
        return {
            options: [],
        }
    },
    computed: {
        model: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit('input', value);
            },
        },
    },
    methods: {
        load() {
            let params = {
                filters: {
                    group: GROUP_PACK_LABEL,
                },
            };

            this.$http.get(LABELS_ENDPOINT, {params}).then((response) => this.options = response.data.data);
        },
    },
    mounted() {
        this.load();
    },
}
</script>
