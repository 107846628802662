<template>
    <b-modal
        v-bind="$attrs"
        ref="modal"
        size="xl"
        scrollable
        centered
        title="Печать состава"
        footer-class="d-flex justify-content-center"
        @hide="onClose"
    >
        <b-row>
            <b-col cols="12">
                <errors-bag-list/>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="8" xl="8" sm="12" class="d-flex justify-content-center align-items-center">
                <b-img v-if="nomenclatureImage" :src="nomenclatureImage" fluid width="310px"/>
                <b-spinner v-else variant="dark"/>
            </b-col>
            <b-col md="4" xl="4">
                <b-form-group>
                    <template #label>
                        Этикетка <sup><b-icon-asterisk variant="danger" scale="0.5"/></sup>
                    </template>
                    <label-select v-model="label"/>
                </b-form-group>
                <b-form-group>
                    <template #label>
                        Принтер <sup><b-icon-asterisk variant="danger" scale="0.5"/></sup>
                    </template>
                    <printer-select v-model="printerId" :label-id="label ? label.id : null" :disabled="!label"/>
                </b-form-group>
                <b-form-group>
                    <template #label>
                        Количество этикеток <sup><b-icon-asterisk variant="danger" scale="0.5"/></sup>
                    </template>
                    <b-form-input
                        type="number"
                        v-model.number="count"
                        autocomplete="off"
                    />
                </b-form-group>
                <b-form-group v-if="canSelectProductionDate">
                    <b-form-checkbox v-model="printProductionDate">Дата производства</b-form-checkbox>
                    <b-form-datepicker
                        v-if="printProductionDate"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        :start-weekday="1"
                        locale="ru"
                        placeholder=""
                        :max="maxProductionDate"
                        v-model="productionDate"
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-if="nomenclature">
            <b-col cols="12">
                <h5>
                    {{ nomenclature.name }}
                    <b-badge
                        v-if="nomenclature.has_mandatory_marking_code"
                        variant="warning"
                        class="ml-2"
                        title="Обязательная маркировка Честный знак (этикетка с Datamatrix)"
                    >
                        ЧЗ
                    </b-badge>
                </h5>
            </b-col>
        </b-row>
        <b-row v-if="nomenclature && nomenclature.nomenclatureComposition">
            <b-col cols="12">
                <p class="text-justify">{{ composition }}</p>
            </b-col>
        </b-row>

        <template #modal-footer="{cancel}">
            <b-button @click="sendToPrint" variant="success">Отправить на печать</b-button>
            <b-button @click="cancel" variant="danger">Отмена</b-button>
        </template>
    </b-modal>
</template>

<script>
import dayjs from 'dayjs';
import ErrorsBag from '@utils/errorsBag';
import ErrorsBagList from '@components/_common/ErrorsBagList';

import LabelSelect from './components/LabelSelect.vue';
import PrinterSelect from './components/PrinterSelect';
import {NomenclatureService, PrinterQueueService} from '@services';

export default {
    name: 'Printing',
    props: {
        value: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        nomenclatureId: {
            type: Number,
            required: true,
        },
        productionPlanIds: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    components: {
        ErrorsBagList,
        LabelSelect,
        PrinterSelect,
    },
    computed: {
        nomenclatureImage() {
            return this.$store.getters["nomenclature/info/image"](this.nomenclatureId)
        },
        composition() {
            let composition = '';

            if (this.nomenclature && this.nomenclature.nomenclatureComposition) {
                composition = [
                    this.nomenclature.nomenclatureComposition.head,
                    this.nomenclature.nomenclatureComposition.body,
                    this.nomenclature.nomenclatureComposition.footer
                ]
                .filter(Boolean)
                .join("\n");
            }

            return composition;
        },
        maxProductionDate() {
            return dayjs().add(1, 'day').format('YYYY-MM-DD');
        },
        canSelectProductionDate() {
            return this.label
                && this.label.hasOwnProperty('has_custom_production_date')
                && this.label.has_custom_production_date === true;
        },
    },
    watch: {
        nomenclatureId: {
            handler: function () {
                this.$store.dispatch("nomenclature/info/getNomenclatureInfo", this.nomenclatureId);
                this.loadNomenclature();
            }
        },
    },
    data() {
        return {
            nomenclature: null,
            printerId: null,
            label: null,
            count: null,
            printProductionDate: true,
            productionDate: dayjs().format('YYYY-MM-DD'),
        }
    },
    methods: {
        onClose() {
            ErrorsBag.discard();

            this.printerId = null;
            this.printProductionDate = true;
            this.productionDate = dayjs().format('YYYY-MM-DD');
            this.label = null;
            this.count = null;
        },
        loadNomenclature() {
            ErrorsBag.discard();
            NomenclatureService.getById(this.nomenclatureId, {
                without_loading: true,
                with: [
                    'nomenclatureComposition',
                ],
            })
            .then(({data}) => this.nomenclature = data)
            .catch(() => {
                this.$bvToast.toast('Не удалось загрузить номенклатуру и состав', {variant: 'danger'});
            });
        },
        sendToPrint() {
            ErrorsBag.discard();
            PrinterQueueService.sendToPrintPackLabel({
                nomenclature_id: this.nomenclatureId,
                production_plan_ids: this.productionPlanIds,
                printer_id: this.printerId,
                label_id: this.label ? this.label.id : null,
                count: this.count,
                production_date: this.canSelectProductionDate && this.printProductionDate ? this.productionDate : null,
                without_loading: true,
            })
            .then(() => {
                this.$bvToast.toast('Этикетки успешно отправлены на печать', {variant: 'success'});
                this.$refs['modal'].hide();
            })
            .catch((response) => {
                if (response.status >= 500) {
                    ErrorsBag.fill(response.data.message);
                } else {
                    ErrorsBag.fill(Object.values(response.data.errors).flat());
                }
            });
        },
    },
}
</script>
