<template>
    <b-container fluid>
        <b-row>
            <b-col cols="6">
                <b-form-group description="Цех">
                    <b-form-select class="form-control" v-model="storage">
                        <b-form-select-option v-for="storage in storages" :key="storage.id" :value="storage">
                            {{ storage.name }}
                        </b-form-select-option>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group description="Дата отгрузки">
                    <b-form-datepicker
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        :start-weekday="1"
                        locale="ru"
                        placeholder=""
                        v-model="date"
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-form-group>
                    <b-form-radio-group
                        v-model="mode"
                        @change="onModeChange"
                        buttons
                        button-variant="outline-primary"
                        class="d-flex flex-wrap"
                    >
                        <b-form-radio :value="MODE_PACKING">
                            <b-icon-box-seam/>
                            Сборка ящиков
                        </b-form-radio>
                        <b-form-radio :value="MODE_PRINTING">
                            <b-icon-printer-fill/>
                            Печать составов
                        </b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </b-col>
        </b-row>
        <hr>

        <b-row v-if="loading" class="text-center">
            <b-col cols="12">
                <b-spinner variant="dark"/>
            </b-col>
        </b-row>
        <b-row v-else-if="tasks.length === 0" class="text-center">
            <b-col cols="12">
                <b-alert variant="info" show="" class="border border-dark">Нет задач</b-alert>
            </b-col>
        </b-row>
        <b-row
            else
            v-for="(task, index) in tasks"
            :key="index"
            @click="select(task)"
        >
            <b-col cols="12">
                <b-alert
                    :variant="classObject(task)"
                    show
                    class="border border-dark"
                >
                    <b-row>
                        <b-col cols="9">
                            <h5>
                                {{ task.nomenclatureName }}
                                <b-badge
                                    v-if="mode === MODE_PRINTING && task.has_mandatory_marking_code"
                                    variant="warning"
                                    class="ml-2"
                                    title="Обязательная маркировка Честный знак (этикетка с Datamatrix)"
                                >
                                    ЧЗ
                                </b-badge>
                            </h5>
                        </b-col>
                        <b-col cols="3" class="text-right">
                            <h5>
                                <b-badge variant="info">
                                    {{ task.productionPlanReasonComment }}
                                </b-badge>
                            </h5>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col v-if="taskSelectable(task)" cols="12">
                            Упаковано: {{ packedAmount(task) }} из {{ requiredAmount(task) }}
                        </b-col>
                        <b-col v-else cols="12">
                            Выпуск не начат
                        </b-col>
                    </b-row>
                </b-alert>

            </b-col>
        </b-row>

        <printing
            id="printing-modal"
            :nomenclature-id="selectedTask.nomenclatureId"
            :production-plan-ids="selectedTask.productionPlanIds"
        />
    </b-container>
</template>

<script>
import moment                                     from 'moment';
import Printing                                   from '../Printing/Printing';
import {NOMENCLATURES_ENDPOINT, PACKING_ENDPOINT} from '@utils/endpoints';
import {BOTTLING_STORAGE_ID, MINI_K_PACKING_STORAGE_ID, PACKING_STORAGE_ID, PARTITION_STORAGE_ID} from '@constants';

const LOCAL_STORAGE_MODE_KEY = 'LS_MODE_TaskList';

const PACKING_STORAGE = {id: PACKING_STORAGE_ID, name: 'Фасовка'};
const MINI_K_PACKING_STORAGE = {id: MINI_K_PACKING_STORAGE_ID, name: 'mini K - Фасовка'};
const BOTTLING_STORAGE = {id: BOTTLING_STORAGE_ID, name: 'Цех розлива напитков'};
const PARTITION_STORAGE = {id: PARTITION_STORAGE_ID, name: 'Цех переборки'};

const MODE_PACKING = 'packing';
const MODE_PRINTING = 'printing';

export default {
    name: 'TaskList',
    components: {
        Printing,
    },
    data() {
        return {
            MODE_PACKING,
            MODE_PRINTING,
            mode: MODE_PRINTING,

            storages: [
                PACKING_STORAGE,
                MINI_K_PACKING_STORAGE,
                BOTTLING_STORAGE,
                PARTITION_STORAGE,
            ],
            storage: PACKING_STORAGE,
            date: moment().format('YYYY-MM-DD'),
            tasks: [],
            loading: false,
            selectedTask: {
                nomenclatureId: 0,
                productionPlanIds: null,
            },
        };
    },
    watch: {
        storage(val) {
            if (val) {
                this.load();
            }
        },
        date(val) {
            if (val) {
                this.load();
            }
        }
    },
    methods: {
        moment,
        requiredAmount(task) {
            return task.requiredAmount
        },
        packedAmount(task) {
            return task.packedAmount
        },
        load() {
            if (!this.date) {
                return;
            }
            if (this.loading) {
                return;
            }

            this.tasks = [];

            this.loading = true;
            this.$http.get(PACKING_ENDPOINT + '/tasks', {
                params: {
                    storageId: this.storage.id,
                    date: moment(this.date).format('YYYY-MM-DD'),
                    without_loading: true,
                }
            }).then(response => {
                this.tasks = response.data;
            }).catch((response) => {
                this.$bvToast.toast(response.data.message, {variant: 'danger'});
            }).finally(() => {
                this.loading = false;
            });
        },
        select(task) {
            if (this.mode === MODE_PRINTING) {
                this.selectedTask = task;
                this.$bvModal.show('printing-modal');
            } else if (this.taskSelectable(task)) {
                this.$emit('select', task);
            }
        },
        fetchNomenclatureByBarcode(barcode) {
            return this.$http
                .get(NOMENCLATURES_ENDPOINT, {
                    params: {
                        filters: {barcode}
                    }
                })
                .then(response => response.data.data.data)
                .then(nomenclatures => {
                    if (Array.isArray(nomenclatures) && nomenclatures.length > 0) {
                        return nomenclatures[0];
                    } else {
                        throw new Error("Nomenclature not found!");
                    }
                })
                .catch(() => {
                    this.$bvToast.toast('Не удалось найти номенклатуру по ШК', {variant: 'danger'});
                })
        },
        onBarcodeScanned(data) {
            this.fetchNomenclatureByBarcode(data.barcode)
                .then(nomenclature => {
                    if (nomenclature) {
                        this.selectedTask.nomenclatureId = nomenclature.id;
                        this.$bvModal.show('printing-modal');
                    }
                })
        },
        onModeChange(mode) {
            localStorage.setItem(LOCAL_STORAGE_MODE_KEY, mode);
            if (mode === MODE_PRINTING) {
                this.$root.listenToBarcodeScanning(this)
            } else {
                this.$root.restorePreviousBarcodeScanning()
            }
        },
        taskSelectable(task) {
            return !!task.nomenclatureLotId
        },
        classObject(task) {
            if (!this.taskSelectable(task)) {
                return 'light';
            }

            if (this.taskSelectable(task)) {
                if (this.packedAmount(task) <= 0) {
                    return 'primary';
                }

                if (this.packedAmount(task) > 0 && this.packedAmount(task) < this.requiredAmount(task)) {
                    return 'warning';
                }

                if (this.packedAmount(task) >= this.requiredAmount(task)) {
                    return 'success';
                }
            }
        }
    },
    created() {
        const mode = localStorage.getItem(LOCAL_STORAGE_MODE_KEY);
        if (mode === MODE_PRINTING) {
            this.$root.listenToBarcodeScanning(this)
        }

        this.mode = [MODE_PACKING, MODE_PRINTING].includes(mode) ? mode : MODE_PACKING;
    },
    mounted() {
        this.load();
    }
}
</script>
